.formContainer {
  /* max-width: 400px; */
  padding: 32px;
  border-radius: 16px;
  /* background: linear-gradient(145deg, #ffffff, #f5f5f5);
    box-shadow: 
      0 10px 25px rgba(0, 0, 0, 0.05),
      0 6px 10px rgba(0, 0, 0, 0.02); */
}

.title {
  color: #1a1a1a;
  margin-bottom: 16px;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.infoContainer {
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.infoContainer p {
  color: #4b5563;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
  text-align: center;
}

.inputGroup {
  margin-bottom: 24px;
  position: relative;
}

.label {
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
  transition: color 0.2s;
}

.hint {
  color: #666;
  font-size: 0.8rem;
  margin-top: 4px;
}

.input {
  width: 100%;
  padding: 14px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  outline: none;
  transition: all 0.2s;
  background-color: rgba(255, 255, 255, 0.9);
}

.input:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 4px rgba(79, 70, 229, 0.1);
}

.input::placeholder {
  color: #a0a0a0;
}

.error {
  color: #ef4444;
  font-size: 0.85rem;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.error:before {
  content: '!';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: #ef4444;
  color: white;
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: bold;
}

.button {
  width: 100%;
  padding: 14px;
  background-color: #4f46e5;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
}

.button:hover {
  background-color: #4338ca;
  transform: translateY(-1px);
}

.button:active {
  transform: translateY(1px);
}

.button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  transform: none;
}

.button:disabled:hover {
  background-color: #e0e0e0;
  transform: none;
}

.successMessage {
  color: #059669;
  margin: 8px 0;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.successMessage:before {
  content: '✓';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #059669;
  color: white;
  border-radius: 50%;
  font-size: 24px;
  margin-bottom: 8px;
  animation: scaleIn 0.3s ease-out;
}

@keyframes scaleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.formContainer {
  animation: slideIn 0.3s ease-out;
  display: block;
  width: 100%;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
